import React, { Component } from 'react';
import M from 'materialize-css';
import Title from '../UI/Title';
import Container from '../UI/Container';
import ListPhoto from '../UI/Galeria/ListPhoto';
import GaleriaData from '../../json/galeria.json';

class Galeria extends Component {
    componentDidMount() {
        var elems = document.querySelectorAll('.carousel');
        M.Carousel.init(elems,{
            dist : -40,
            indicators : true
        });

        var el = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(el);
    }
    render() {
        return(
            <Container>
                <Title
                    first='Video y Imágenes'
                    second='GALERÍA'
                />
                {
                    GaleriaData.map((item,key) =>
                        <ListPhoto data={item}/>
                    )
                }
            </Container>    
            )
    }
}

export default Galeria;