import React from 'react';
import LogoWhite from '../../assets/Logo-White.svg';

function Nav(props) {
  return (
    // navbar-fixed
    <div className={props.scrollTop >= 97.33 ? 'navbar-fixed' : ''}>
      <nav className='Nav-nav Nav-fixed'>
        <div className="nav-wrapper">
          <a href="#" className="brand-logo"><img alt='LogoRemoval-white' src={LogoWhite} className={props.scrollTop >= 97.33 ? 'Nav-logoFixed u-op-1' : 'Nav-logoFixed u-op-0' } width='40'/></a>
          <a href="#" data-target="slide-out" className="sidenav-trigger right">
            <i className="far fa-bars"></i>
          </a>
          <ul className="right hide-on-med-and-down">
            {props.children}
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default Nav;