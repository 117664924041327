import React, { Component, Fragment } from 'react';
import Title from '../UI/Title';
import Container from '../UI/Container';
import SectionGray from '../UI/SectionGray';
import ServCard from '../UI/Servicios/ServCard';
import ServData from '../../json/servicios.json';
import servicio1 from '../../assets/slider3.png';
import servicio2 from '../../assets/Servicios/servicios-2.png';

class Servicio extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Title
                        first='Nuestros'
                        second='SERVICIOS'
                    />
                </Container>
                <SectionGray>
                    <div className='row'>
                        <ServCard 
                        data={ServData} 
                        key={1}
                        img={servicio1}
                        title='Proyectos Automatización' 
                        subtitle='Evaluamos y desarrollamos proyectos para los procesos de la industria con implementaciones de:' 
                        text='' />
                        <ServCard 
                        key={2}
                        data={ServData} 
                        img={servicio2}
                        title='Fabricación de tableros Electricos' 
                        subtitle='Fabricación de tableros de control y potencia de media y  baja tensión con diseño de plano eléctrico.'
                        text='' />
                    </div>
                </SectionGray>
            </Fragment>
        )
    }
}

export default Servicio;