import React, { Component } from 'react';
import Title from '../UI/Title';
import Container from '../UI/Container';
import FormContact from '../UI/Form/FormContact';

class Contacto extends Component {
    render() {
        return(
            <Container>
                <Title
                    first='Envíanos un mensaje'
                    second='CONTÁCTO'
                />
                <div className='row'>
                    <div className='col-lg-5'>
                        <div className='card'>
                            <div className='card-content'>
                            <span className="card-title">Rellena el formulario</span><br/>
                            <FormContact />
                            </div>
                        </div>
                        <div className='Contacto-Bottext mt-5'>
                            <h5 className='mb-4'>Datos de contacto</h5>
                            <span className='mt-2 d-inline-block'><i className="fal fa-phone-square mr-2"></i> +56-2 25489169</span><br/>
                            <span className='mt-2 d-inline-block'><i className="fal fa-envelope-square mr-2"></i> ventas@removal.cl </span><br/>
                            <span className='mt-2 d-inline-block'>
                                <i className="fal fa-map-marked-alt mr-2"></i>
                                Las Gaviotas N° 8848 - La Cisterna - Santiago - Chile</span>
                        </div>
                    </div>
                    <div className='col-lg-7 mt-lg-0 mt-5'>
                        <div className='card'>
                            <div className='card-content'>
                                <iframe title='Ubication' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.630682940835!2d-70.67072974857535!3d-33.53698650925165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662da55300bd819%3A0x1adc013b66be6aa3!2sLas+Gaviotas+8848%2C+La+Cisterna%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1ses!2scl!4v1557017635908!5m2!1ses!2scl" width='100%' height='400' frameBorder="0" allowFullScreen={true}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}

export default Contacto;