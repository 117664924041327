import React from 'react';
import { Link } from "react-router-dom";
import FormContact from "../UI/Form/FormContact";
import packageJson from '../../../package.json';

function Footer(props) {
    return (
        <div className="Footer-container mt-5 py-4 mb-0">
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-4 Footer-menu d-flex justify-content-center'>
                        <ul>
                            <li><h6><b>Menu</b></h6></li>
                            <li><Link to='/'>Inicio</Link></li>
                            <li><Link to='/Productos'>Productos</Link></li>
                            <li><Link to='/Servicios'>Servicios</Link></li>
                            <li><Link to='/Galeria'>Galeria</Link></li>
                            <li><Link to='/Contacto'>Contacto</Link></li>
                        </ul>
                    </div>
                    <div className='col-sm-4 mt-sm-0 mt-5 px-sm-0 px-5'>
                        <div className='row'>
                            <FormContact subtitle={true} />
                        </div>
                    </div>
                    <div className='col-sm-4 d-flex justify-content-center mt-sm-0 mt-5'>
                        <div>
                            <div className='col-12'>
                                <h6><b>Contácto</b></h6>
                            </div>
                            <div className='col-12 mt-3'>
                                <span>
                                    <i className="fal fa-phone-square mr-1"></i> +56-2 25489169
                                    </span>
                            </div>
                            <div className='col-12 mt-2'>
                                <span>
                                    <i className="fal fa-envelope-square mr-1"></i> ventas@removal.cl
                                    </span>
                            </div>
                            <div className='col-12 mt-2'>
                                <span>
                                    <i className="fal fa-map-marked-alt mr-1"></i>
                                    Las Gaviotas N° 8848 <br />
                                    La Cisterna - Santiago - Chile
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Footer-version'>
                <small className='white'>Version {packageJson.version}</small>
            </div>
        </div>
    )
}

export default Footer;