import React from 'react';

function Title(props) {
  return (
    <div className='row'>
        <div className='col-12'>
            <h2 className='Title'>
                {props.first}<br />
                <font>{props.second}</font>
            </h2>
        </div>
    </div>
  )
}

export default Title;