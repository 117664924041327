import React from 'react';

function List(props) {
    const data = props.data;
    const img = data.img;
    const title = data.title;
    const text = data.text;
    const cards = data.cards;
    const product = data.product;

    return (
        <li className={props.active ? 'active' : ''}>
            <div className="collapsible-header d-flex align-items-center">
                <img src={img} className='List-header-img mr-4'/>
                <span><b>{title}</b></span>
            </div>
            <div className="collapsible-body Link-body-contain">
                <div className='row'>
                    <div className='col-lg-4 center-align d-flex align-items-center justify-content-center'><img src={img} className='List-logo'/></div>
                    <div className='col-lg-8 mt-lg-0 mt-3'>
                        <h4>{title}</h4>
                        <p>{text}</p>
                    </div>
                </div>
                {
                    cards
                        ?
                        <div className='row'>
                            {
                                product.map(item => 
                                    <div className='col-12 col-md-6 col-lg-3 Link-pro-contain' key={item.id}>
                                        <a href={item.link} target="_blank">
                                            <div className='card'>
                                                <div className='card-image'>
                                                    <img src={item.img} />
                                                    <span className="card-title">{item.title}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            }
                            <div className="col-12 mt-5">
                                <h5><b>Certificaciones</b></h5>
                                <p>Si necesita imprimir alguna de estas certificaciones de Cidepa-Sincron puede hacerlo mediante un "clic" en el link correspondiente:</p>
                                <div className="row">
                                    <div className="col-md-6 mt-md-0 mt-4 center-align">
                                    <a href="http://www.cidepa-sincron.com/contenidos/pdf/certificadoISO9001.pdf" target="_blank">
                                        <div className="card u-anim-up">
                                            <div className="card-content">
                                                <h6>DESCARGA CERTIFICADO ISO 9001:2008</h6>
                                                <i className="u-c-orange fa fa-file-alt fa-3x"></i>
                                            </div>
                                        </div>
                                    </a>
                                    </div>
                                    <div className="col-md-6 mt-md-0 mt-4 center-align">
                                        <a href="https://www.cidepa-sincron.com/contenidos/pdf/certificadoISO14001.pdf" target="_blank">
                                        <div className="card u-anim-up">
                                            <div className="card-content">
                                                <h6>DESCARGA CERTIFICADO ISO 14001</h6>
                                                <i className="u-c-orange fa fa-file-alt fa-3x"></i>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='row'>
                            {
                                product.map((item) => 
                                    <div className='col-12' key={item.id}>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <a href={item.link} target='_blank'>
                                                    <h5 className='u-c-orange'>{item.title}</h5>
                                                </a>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='card center-align'>
                                                        {
                                                            item.images.length > 1
                                                            ? 
                                                            <div className="carousel indicator-gray">
                                                                {
                                                                    item.images.map((photo,key) => 
                                                                        <a className="carousel-item" href="#one!" key={key}><img className="materialboxed" src={photo}/></a>
                                                                    )
                                                                } 
                                                            </div>
                                                         : <img src={item.images[0]} className='List-cardImg materialboxed'/>
                                                        }
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='row'>
                                                    {
                                                        item.features !== '' &&
                                                        <div className='col-12'>
                                                            <h5>Características:</h5>
                                                            <p dangerouslySetInnerHTML={{ __html: item.features }} />
                                                        </div>
                                                    }
                                                    {
                                                        item.aplications &&
                                                        <div className='col-12'>
                                                            <h5>Aplicaciones:</h5>
                                                            <p dangerouslySetInnerHTML={{ __html: item.aplications }} />
                                                        </div>
                                                    }
                                                    {
                                                        item.potencia &&
                                                        <div className='col-12'>
                                                            <h5>Potencia:</h5>
                                                            <p dangerouslySetInnerHTML={{ __html: item.potencia }} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                }
            </div>
        </li>
    )
}

export default List;