import React from 'react';
import Container from './Container';

function SectionGray(props) {
  return (
    <div className='u-bg-gray py-5'>
        <Container>{props.children}</Container>
    </div>
  )
}

export default SectionGray;