import React from 'react';
import Slider1 from '../../assets/fondo-slider-1.jpg';
import Photo1 from '../../assets/photo-slider-1.png';
import Slider2 from '../../assets/fondo-slider-2.jpg';
import Slider3 from '../../assets/slider3.png';
import Photo2 from '../../assets/photo-slider-2.png';
import Video1 from '../../assets/videos/videoSlider.mp4';

function Slider(props) {
    var width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;


    return (
        <div className="slider">
            <ul className="slides">
                {
                width >= 700 
                ?
                <li>
                    <video src={Video1} width="100%" autoPlay={true} audio loop/>
                    <div className="caption center-align">
                        <h3 className='u-bold u-text-shadow u-fz-md-3'>PROYECTOS</h3>
                        <h5 className="u-bold u-text-shadow grey-text text-lighten-3">Evaluamos y desarrollamos proyectos para los procesos de la industria</h5>
                        <img src={Photo2} className="Slider-photo-1 d-none" alt="motoreductor" />
                    </div>
                </li>
                :
                <li>
                    <img src={Slider3} alt="industria_slider" />
                    <div className="caption center-align">
                        <h3 className='u-bold u-text-shadow u-fz-md-3'>PROYECTOS</h3>
                        <h5 className="u-bold u-text-shadow grey-text text-lighten-3">Evaluamos y desarrollamos proyectos para los procesos de la industria</h5>
                        {/* <img src={Photo2} className="Slider-photo-1" alt="motoreductor" /> */}
                    </div>
                </li>
                }
                <li>
                    <img src={Slider2} alt="industria_job" />
                    <div className="caption center-align">
                        <h3 className='u-bold u-text-shadow u-fz-md-3'>MOTOREDUCTORES</h3>
                        <h5 className="u-bold u-text-shadow grey-text text-lighten-3">Concebido para adaptarse a cualquer sistema de maquinaria</h5>
                        <img src={Photo2} className="Slider-photo-1" alt="motoreductor" />
                    </div>
                </li>
                <li>
                    <img src={Slider1} alt="industria_fondo" />
                    <div className="caption center-align">
                        <h3 className='u-bold u-text-shadow u-fz-md-3'>VARIDORES DE FRECUENCIA</h3>
                        <h5 className="u-bold u-text-shadow grey-text text-lighten-3">SOLUCIONES INDUSTRIALES</h5>
                        <img src={Photo1} className="Slider-photo-1" alt="motoreductor" />
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Slider;