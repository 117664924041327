import React from 'react';
import { Link } from 'react-scroll';

function TabProducts(props) {
    const data = props.data
    return(
        <div className='card u-max-content m-auto'>
            <ul className="tabs ProductsTabs">
                {
                    data.map(item => {
                        const id = item.id - 1
                        return(
                            <li className="tab align-items-center" key={item.id}><Link href="#"  to="productos"  spy={true} smooth={true} offset={-70} duration={500} onClick={() => {props.moveToCollapsible(id)}}>
                                {item.title}</Link>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default TabProducts;