import React from 'react';

function SideNav(props) {
  return (
    <ul id="slide-out" className="sidenav">
        {props.children}
    </ul>
  )
}

export default SideNav;