import React from 'react';
import './App.css';
import Layout from './Layout';
import { HashRouter } from 'react-router-dom';
import 'materialize-css/dist/css/materialize.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'bootstrap/dist/css/bootstrap-grid.css';

function App() {
  return (
    <HashRouter>
      <Layout />
    </HashRouter>
  );
}

export default App;
