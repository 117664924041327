import React, { Fragment } from 'react';
import { Formik, Form, } from 'formik';
import InputText from './InputText';
import $ from 'jquery';
import M from 'materialize-css';

function FormContact(props) {
    return (
        <Fragment>
            <Formik
                initialValues={{
                    name: '',
                    mail: '',
                    fone: '',
                    message: ''
                }}
                onSubmit={values => {
                    console.log(values);

                    // $.post("./mail.php", function (data) {
                    //     console.log(data)
                    // });

                    $.ajax({
                        type: "POST",
                        url: "./mail.php",
                        data: values,
                        success: function (datos) {
                            console.log(datos)
                            M.toast({html: 'Mensaje enviado con éxito'})
                        }
                    }).fail(()=>{
                        M.toast({html: 'Error, mensaje no enviado'})
                    })

                }}
                render={(props) =>
                    <div className='row'>
                        <Form className="w-100">
                            {props.subtitle && <h6>Envianos un mensaje</h6>}
                            <InputText
                                type='text'
                                name='name'
                                id='name'
                                label='Nombre'
                                col='col-12'
                                icon='fal fa-user'
                            />
                            <InputText
                                type='email'
                                name='mail'
                                id='mail'
                                label='Correo'
                                col='col-12'
                                icon='fal fa-envelope'
                            />
                            <InputText
                                type='number'
                                name='fone'
                                id='fone'
                                label='Teléfono'
                                col='col-12'
                                icon='fal fa-mobile-alt'
                            />
                            <InputText
                                type='textarea'
                                name='message'
                                id='message'
                                label='Mensaje'
                                col='col-12'
                                icon='fal fa-file-alt'
                            />
                            <div className='col s12'>
                                <button
                                    type="submit"
                                    className='waves-effect waves-light btn-small'>
                                    Enviar
                                </button>
                            </div>
                        </Form>
                    </div>
                }
            />
        </Fragment>

    )
}

export default FormContact;