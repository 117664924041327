import React, { Component } from 'react';

class Inicio extends Component {
    render() {
        return(
            <h1><i class="far fa-phone"></i> Error 404</h1>
        )
    }
}

export default Inicio;