import React from 'react';

function ListPhoto(props) {
  console.log(props.data)
  return (
    <div className='container'>
      <h5>{props.data.title}</h5>
      <div className='carousel indicator-gray'>
        {
          props.data.photo.map(item =>
            <button class="carousel-item u-btn-nostyle"><img src={item} className="u-w-100 materialboxed" /></button>
          )
        }
      </div>
    </div>

  );

}

export default ListPhoto;