import React from 'react';
import logo from '../../assets/Logo.svg';

function Nav(props) {
    return (
        <div className="TopNav-container">
            <div className='container'>
                <div className='row py-4 mb-0 d-flex align-items-center'>
                    <div className='col s6'>
                        <img alt='LogoRemoval' src={logo} className='TopNav-logo'/>
                    </div>
                    <div className='col s6 right-align TopNav-contact'>
                        <span className='mr-3 d-block'>
                            <i className="far fa-phone-square mr-1"></i>
                            +56-2 25489169
                        </span>
                        <span>
                            <i className="far fa-envelope-square mr-1"></i>
                            ventas@removal.cl   
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nav;