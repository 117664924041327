import React from 'react';
import { Field } from 'formik';

function InputText(props) {
  return (
    <div className={props.col && props.col}>
      <div className='input-field'>

        {props.icon && <i className={'material-icons prefix ' + props.icon} />}

        {props.type === 'textarea'
          ? <Field component="textarea" {...props} id={props.name} className="materialize-textarea" data-length="120" />
          : <Field {...props} id={props.name} />
        }

        <label htmlFor={props.name}>{props.label}</label>

      </div>
    </div >
  )
}

export default InputText;