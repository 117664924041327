import React, { Component } from 'react';
import Title from '../UI/Title';
import Container from '../UI/Container';
import M from 'materialize-css';
import ProductData from '../../json/products.json';
import List from '../UI/Productos/List';
import TabProducts from '../UI/Productos/TabProducts';
import { Element } from 'react-scroll';

class Producto extends Component {
    moveNextCarousel = () => {
        let elem = document.querySelector('.carousel');
        let instance = M.Carousel.getInstance(elem);
        instance.next();
    }

    moveToCollapsible = (num) => {
        let elem = document.querySelector('.collapsible');
        let instance = M.Collapsible.getInstance(elem);
        instance.open(num);
    }

    componentDidMount() {
        let elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems, {onOpenEnd : () => {
            let elem = document.querySelectorAll('.carousel');
            M.Carousel.init(elem, {indicators : true});

            var elemn = document.querySelectorAll('.materialboxed');
            M.Materialbox.init(elemn);
        }});
        let el = document.querySelectorAll('.tabs');
        M.Tabs.init(el);

        let tab = document.querySelector('.tabs');
        var instance = M.Tabs.getInstance(tab);
        instance.destroy();
    }

    render() {
        return (
            <Container>
                <Title
                    first='Nuestros'
                    second='PRODUCTOS'
                />
                <TabProducts data={ProductData} moveToCollapsible={this.moveToCollapsible} />
                <Element name="productos">
                <ul className="collapsible mt-4">
                    {
                        ProductData.map((item,key)=> 
                            <List data ={item} active={key === 0 ? true : false} key={item.id}/> 
                        )
                    }
                </ul>
                </Element>
            </Container>
        )
    }
}

export default Producto;