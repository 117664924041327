import React, { Component, Fragment } from 'react';
import Slider from '../../Components/UI/Slider';
import M from 'materialize-css';
import ServData from '../../json/servicios.json';
import ServCardInicio from '../UI/Inicio/ServCardInicio';
import LogoCidepa from '../../assets/logoCidepa.png';
import LogoInvt from '../../assets/logoInvt.png';
import LogoSiemens from '../../assets/logoSiemens.png';
import LogoYaskawa from '../../assets/logoYaskawa.png';
import servicio1 from '../../assets/slider3.png';
import servicio2 from '../../assets/Servicios/servicios-2.png';

class Inicio extends Component {
    componentDidMount() {
        var elems = document.querySelectorAll('.slider');
        M.Slider.init(elems,{
            interval:15000,
            height:700,
            indicators:true,
        });
    }

    render() {
        return (
            <Fragment>
                <Slider />
                <section className='container mt-5'>
                    <div className='row justify-content-center py-5'>
                        <div className='col-lg-8 center-align'>
                            <h4 className='Inicio-title'>Quienes somos</h4>
                            <p className='Inicio-p'>
                                Somos distribuidores, importamos y comercializamos equipos
                                para el control de movimiento, mediante ingeniería calificada y
                                soporte técnico, adecuando productos a las necesidades del
                                mercado Chileno.</p>
                        </div>
                    </div>
                </section>
                <section className='Inicio-fondoElectric'><div></div></section>
                <section className='mb-5  u-bg-lightgray'>
                    <div className='container'>
                        <div className='row justify-content-center pt-5'>
                            <div className='col-lg-8 center-align'>
                                <h4 className='Inicio-title'>¿Qué ofrecemos?</h4>
                                <p className='Inicio-p'>
                                    Productos que se encuentran adaptados a las necesidades del mercado</p>
                            </div>
                        </div>
                        <div className='row justify-content-center py-5'>
                            <ServCardInicio 
                                data={ServData} 
                                key={1}
                                img={servicio1}
                                title='Proyectos Automatización' 
                                subtitle='Evaluamos y desarrollamos proyectos para los procesos de la industria con implementaciones de:' 
                                text='' />
                            <ServCardInicio 
                                key={2}
                                data={ServData} 
                                img={servicio2}
                                title='Fabricación de tableros Electricos' 
                                subtitle='Fabricación de tableros de control y potencia de media y  baja tensión con diseño de plano eléctrico.'
                                text='' />
                        </div>
                    </div>
                </section>
                <section className='container my-5'>
                    <div className='row justify-content-center pt-5'>
                        <div className='col-12 center-align'>
                            <h5 className='Inicio-title2'>Contamos con la representación de</h5>
                            <img className='Inicio-imgRepresent mt-4' src={LogoCidepa}/>
                        </div>
                        <hr />
                        <div className='col-12 my-5 center-align'>
                            <h5 className='Inicio-title2'>Trabajamos las mejores marcas</h5>
                            <div className='row align-items-center justify-content-center Inicio-marcas mt-4'>
                                <div className='col-md-4 mt-md-0 mt-5'><img src={LogoInvt} className='w-100'/></div>
                                <div className='col-md-4 mt-md-0 mt-5'><img src={LogoSiemens} className='w-100'/></div>
                                <div className='col-md-4 mt-md-0 mt-5'><img src={LogoYaskawa} className='w-100'/></div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

export default Inicio;