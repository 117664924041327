import React, { Component, Fragment } from 'react';
import './Layout.css';
import { Route, Switch, NavLink, withRouter, Link } from "react-router-dom";
import M from 'materialize-css';
import Inicio from './Components/Views/Inicio';
import Productos from './Components/Views/Productos';
import Servicios from './Components/Views/Servicios';
import Galeria from './Components/Views/Galeria';
import Contacto from './Components/Views/Contacto';
import Error from './Components/Views/Error';
import Nav from './Components/UI/Nav';
import TopNav from './Components/UI/TopNav';
import SiderNav from './Components/UI/SideNav';
import Footer from './Components/Layout/Footer';

class Layout extends Component {
    state = {
        scrollTop : 0
    }
    getNavLinkClass = (path) => {
        return this.props.location.pathname === path ? 'active' : '';
    }

    componentDidMount() {
        // Sidenav
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems);

        //Scroll Menu fixed
        this.handleScroll()
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        this.setState({scrollTop});
    }

    render() {
        return (
            <Fragment>
                <TopNav/>
                <Nav scrollTop={this.state.scrollTop}>
                    <li className={this.getNavLinkClass("/")}><NavLink to='/'>Inicio</NavLink></li>
                    <li className={this.getNavLinkClass("/Productos")}><NavLink to='/Productos'>Productos</NavLink></li>
                    <li className={this.getNavLinkClass("/Servicios")}><NavLink to='/Servicios'>Servicios</NavLink></li>
                    <li className={this.getNavLinkClass("/Galeria")}><NavLink to='/Galeria'>Galeria</NavLink></li>
                    <li className={this.getNavLinkClass("/Contacto")}><NavLink to='/Contacto'>Contacto</NavLink></li>
                </Nav>
                <SiderNav>
                    <li><a className="subheader">Menu</a></li>
                    <li><div className="divider"></div></li>
                    <li><Link className="waves-effect" to="/">Inicio</Link></li>
                    <li><Link className="waves-effect" to="/Productos">Productos</Link></li>
                    <li><Link className="waves-effect" to="/Servicios">Servicios</Link></li>
                    <li><Link className="waves-effect" to="/Galeria">Galeria</Link></li>
                    <li><Link className="waves-effect" to="/Contacto">Contacto</Link></li>
                </SiderNav>
                <Switch>
                    <Route exact path="/" component={Inicio} />
                    <Route exact path="/Productos" component={Productos} />
                    <Route exact path="/Servicios" component={Servicios} />
                    <Route exact path="/Galeria" component={Galeria} />
                    <Route exact path="/Contacto" component={Contacto} />
                    <Route component={Error} />
                </Switch>
                <Footer />
            </Fragment>
        )
    }
}

export default withRouter(Layout);