import React from 'react';
import { Link } from 'react-router-dom';

function ServCardInicio(props) {
    return (
        <div className='col-lg-6' key={props.key}>
            <div className="card ServCard-card">
                <div className="card-image">
                    <img src={props.img} />
                </div>
                <div className="card-content">
                    <h4>{props.title}</h4>
                    <h6>{props.subtitle}</h6>
                    <p>{props.text}</p>
                </div>
                <div className="card-action right-align">
                    <Link to='/Contacto' className='waves-effect waves-light btn mt-4'>Contratar</Link>
                </div>
            </div>
        </div>
    )
}

export default ServCardInicio;